import React, { useContext, useEffect, useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import { ArrowRight } from '@mui/icons-material';
import useAxios from '../utils/useAxios';
import tableImage from './table.svg';
import chairImage from './chair.svg';
import AuthContext from '../context/AuthContext';

function Table() {
  const {logoutUser} = useContext(AuthContext)
  const params = useParams()
  const api = useAxios();
  const [tableData, setTableData] = useState([]);

  const getTable = async () => {
    const response = await api.get('/store/tableno');
    setTableData(response.data);
  };

  useEffect(() => {
    getTable();
  }, []);

  return (
    <div className="table-container">
      <div>
        <button className='button-logout' onClick={() => logoutUser()}>Logout</button>
      </div>
      <Link to={'/orders'}>
      <div className="view-order">
        View Order 
      </div>
      </Link>
      <div className="table-body">
        {tableData.map((table) => (
          <div key={table.id} className="table-row">
            <Link to={`/home/${table.id}`}>
            <div className="table-image-container">
              <img src={chairImage} alt="Chair" className="chair-image chair-left" />
              <img src={tableImage} alt="Table" className="table-image" />
              <img src={chairImage} alt="Chair" className="chair-image chair-right" />
            </div>
            <div className="table-info-container">
              <div className="table-number">Table No. {table.table_no}</div>
              <div className="table-action">Take Order <ArrowRight /></div>
            </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Table;
