import React from 'react'
import MenuContainer from './MenuContainer'
import {
    HomeRounded,
    Notifications,
    TableBarRounded,
  } from "@mui/icons-material"
import { useParams } from 'react-router-dom'

const ButtomNav = () => {
  const params = useParams()
  return (
    <div className="leftMenu">
        <ul id="menu">
          <MenuContainer className= 'icon-nav' link= {'/'} icon = {<TableBarRounded />} />
          <MenuContainer className= 'icon-nav' link = {'/home/'+params.id} icon = {<HomeRounded />}  isHome/>
          <MenuContainer  className= 'icon-nav'  link = {'/orders'} icon = {<Notifications />}  />
          <div className="indicator"></div>
        </ul>
      </div>
  )
}

export default ButtomNav